// @generated by protoc-gen-es v1.0.0 with parameter "target=ts+js+dts"
// @generated from file hotel/v1/query.proto (package hotel.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Date } from "../../google/type/date_pb.js";

/**
 * @generated from message hotel.v1.HotelSearchQuery
 */
export const HotelSearchQuery = proto3.makeMessageType(
  "hotel.v1.HotelSearchQuery",
  () => [
    { no: 1, name: "check_in", kind: "message", T: Date },
    { no: 2, name: "check_out", kind: "message", T: Date },
    { no: 3, name: "adult_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "child_ages", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 5, name: "location_scope_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "min_star_rating", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "max_star_rating", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "min_price", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "max_price", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "with_accessibility", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "with_amenity_codes", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 12, name: "category", kind: "enum", T: proto3.getEnumType(HotelSearchQuery_Category) },
    { no: 13, name: "filter", kind: "enum", T: proto3.getEnumType(HotelSearchQuery_Selector) },
    { no: 14, name: "rate_option", kind: "enum", T: proto3.getEnumType(HotelSearchQuery_RateOption) },
    { no: 15, name: "sales_environment", kind: "enum", T: proto3.getEnumType(HotelSearchQuery_SalesEnvironment) },
    { no: 16, name: "currency_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "sales_channel", kind: "enum", T: proto3.getEnumType(HotelSearchQuery_SalesChannel) },
    { no: 18, name: "customer_country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "room_selections", kind: "message", T: RoomSelectionRequest, repeated: true },
  ],
);

/**
 * @generated from enum hotel.v1.HotelSearchQuery.Category
 */
export const HotelSearchQuery_Category = proto3.makeEnum(
  "hotel.v1.HotelSearchQuery.Category",
  [
    {no: 0, name: "CATEGORY_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CATEGORY_PROPERTY", localName: "PROPERTY"},
    {no: 2, name: "CATEGORY_HOTEL", localName: "HOTEL"},
    {no: 3, name: "CATEGORY_MOTEL", localName: "MOTEL"},
    {no: 4, name: "CATEGORY_RESORT", localName: "RESORT"},
    {no: 5, name: "CATEGORY_INN", localName: "INN"},
    {no: 6, name: "CATEGORY_BED_BREAKFAST", localName: "BED_BREAKFAST"},
    {no: 7, name: "CATEGORY_GUESTHOUSE", localName: "GUESTHOUSE"},
    {no: 8, name: "CATEGORY_CONDO", localName: "CONDO"},
    {no: 9, name: "CATEGORY_ALL_INCLUSIVE_PROPERTY", localName: "ALL_INCLUSIVE_PROPERTY"},
    {no: 10, name: "CATEGORY_CABIN", localName: "CABIN"},
    {no: 11, name: "CATEGORY_CHALET", localName: "CHALET"},
    {no: 12, name: "CATEGORY_COTTAGE", localName: "COTTAGE"},
    {no: 13, name: "CATEGORY_HOSTEL_BACKPACKER_ACCOMMODATION", localName: "HOSTEL_BACKPACKER_ACCOMMODATION"},
    {no: 14, name: "CATEGORY_RANCH", localName: "RANCH"},
    {no: 15, name: "CATEGORY_VILLA", localName: "VILLA"},
    {no: 16, name: "CATEGORY_LODGE", localName: "LODGE"},
    {no: 17, name: "CATEGORY_APARTMENT", localName: "APARTMENT"},
    {no: 18, name: "CATEGORY_PRIVATE_VACATION_HOME", localName: "PRIVATE_VACATION_HOME"},
    {no: 19, name: "CATEGORY_HOUSEBOAT", localName: "HOUSEBOAT"},
    {no: 20, name: "CATEGORY_RYOKAN", localName: "RYOKAN"},
    {no: 21, name: "CATEGORY_TREE_HOUSE", localName: "TREE_HOUSE"},
    {no: 22, name: "CATEGORY_APARTHOTEL", localName: "APARTHOTEL"},
    {no: 23, name: "CATEGORY_CONDOMINIUM_RESORT", localName: "CONDOMINIUM_RESORT"},
    {no: 24, name: "CATEGORY_CAMPSITE", localName: "CAMPSITE"},
    {no: 25, name: "CATEGORY_RIAD", localName: "RIAD"},
    {no: 26, name: "CATEGORY_HOSTAL", localName: "HOSTAL"},
    {no: 27, name: "CATEGORY_COUNTRY_HOUSE", localName: "COUNTRY_HOUSE"},
    {no: 28, name: "CATEGORY_PENSION", localName: "PENSION"},
    {no: 29, name: "CATEGORY_POUSADA_PORTUGAL", localName: "POUSADA_PORTUGAL"},
    {no: 30, name: "CATEGORY_POUSADA_BRAZIL", localName: "POUSADA_BRAZIL"},
    {no: 31, name: "CATEGORY_RESIDENCE", localName: "RESIDENCE"},
    {no: 32, name: "CATEGORY_TOWN_HOUSE", localName: "TOWN_HOUSE"},
    {no: 33, name: "CATEGORY_CASTLE", localName: "CASTLE"},
    {no: 34, name: "CATEGORY_SAFARI_TENTALOW", localName: "SAFARI_TENTALOW"},
    {no: 35, name: "CATEGORY_PALACE", localName: "PALACE"},
    {no: 36, name: "CATEGORY_AGRITOURISM_PROPERTY", localName: "AGRITOURISM_PROPERTY"},
    {no: 37, name: "CATEGORY_CRUISE", localName: "CRUISE"},
    {no: 38, name: "CATEGORY_HOLIDAY_PARK", localName: "HOLIDAY_PARK"},
  ],
);

/**
 * @generated from enum hotel.v1.HotelSearchQuery.Selector
 */
export const HotelSearchQuery_Selector = proto3.makeEnum(
  "hotel.v1.HotelSearchQuery.Selector",
  [
    {no: 0, name: "SELECTOR_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SELECTOR_ADVENTURE_SPORTS", localName: "ADVENTURE_SPORTS"},
    {no: 2, name: "SELECTOR_BEACH", localName: "BEACH"},
    {no: 3, name: "SELECTOR_BOUTIQUE", localName: "BOUTIQUE"},
    {no: 4, name: "SELECTOR_BUDGET", localName: "BUDGET"},
    {no: 5, name: "SELECTOR_BUSINESS", localName: "BUSINESS"},
    {no: 6, name: "SELECTOR_CASINO", localName: "CASINO"},
    {no: 7, name: "SELECTOR_CITY", localName: "CITY"},
    {no: 8, name: "SELECTOR_FAMILY", localName: "FAMILY"},
    {no: 9, name: "SELECTOR_GOLF", localName: "GOLF"},
    {no: 10, name: "SELECTOR_HISTORIC", localName: "HISTORIC"},
    {no: 11, name: "SELECTOR_HOMES", localName: "HOMES"},
    {no: 12, name: "SELECTOR_HOSTEL", localName: "HOSTEL"},
    {no: 13, name: "SELECTOR_HOT_SPRINGS", localName: "HOT_SPRINGS"},
    {no: 14, name: "SELECTOR_HOTEL", localName: "HOTEL"},
    {no: 15, name: "SELECTOR_LUXURY", localName: "LUXURY"},
    {no: 16, name: "SELECTOR_MOTEL", localName: "MOTEL"},
    {no: 17, name: "SELECTOR_NATURAL", localName: "NATURAL"},
    {no: 18, name: "SELECTOR_PET_FRIENDLY", localName: "PET_FRIENDLY"},
    {no: 19, name: "SELECTOR_RESORT", localName: "RESORT"},
    {no: 20, name: "SELECTOR_ROMANTIC", localName: "ROMANTIC"},
    {no: 21, name: "SELECTOR_SHOPPING", localName: "SHOPPING"},
    {no: 22, name: "SELECTOR_SKI", localName: "SKI"},
    {no: 23, name: "SELECTOR_SPA", localName: "SPA"},
    {no: 24, name: "SELECTOR_SUSTAINABLE", localName: "SUSTAINABLE"},
    {no: 25, name: "SELECTOR_UNIQUE", localName: "UNIQUE"},
    {no: 26, name: "SELECTOR_WINERY", localName: "WINERY"},
  ],
);

/**
 * @generated from enum hotel.v1.HotelSearchQuery.RateOption
 */
export const HotelSearchQuery_RateOption = proto3.makeEnum(
  "hotel.v1.HotelSearchQuery.RateOption",
  [
    {no: 0, name: "RATE_OPTION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "RATE_OPTION_MEMBER", localName: "MEMBER"},
    {no: 2, name: "RATE_OPTION_NET_RATES", localName: "NET_RATES"},
    {no: 3, name: "RATE_OPTION_CROSS_SELL", localName: "CROSS_SELL"},
  ],
);

/**
 * @generated from enum hotel.v1.HotelSearchQuery.SalesEnvironment
 */
export const HotelSearchQuery_SalesEnvironment = proto3.makeEnum(
  "hotel.v1.HotelSearchQuery.SalesEnvironment",
  [
    {no: 0, name: "SALES_ENVIRONMENT_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SALES_ENVIRONMENT_HOTEL_PACKAGE", localName: "HOTEL_PACKAGE"},
    {no: 2, name: "SALES_ENVIRONMENT_LOYALTY", localName: "LOYALTY"},
    {no: 3, name: "SALES_ENVIRONMENT_HOTEL_ONLY", localName: "HOTEL_ONLY"},
  ],
);

/**
 * @generated from enum hotel.v1.HotelSearchQuery.SalesChannel
 */
export const HotelSearchQuery_SalesChannel = proto3.makeEnum(
  "hotel.v1.HotelSearchQuery.SalesChannel",
  [
    {no: 0, name: "SALES_CHANNEL_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SALES_CHANNEL_WEBSITE", localName: "WEBSITE"},
    {no: 2, name: "SALES_CHANNEL_AGENT_TOOL", localName: "AGENT_TOOL"},
    {no: 3, name: "SALES_CHANNEL_MOBILE_APP", localName: "MOBILE_APP"},
    {no: 4, name: "SALES_CHANNEL_MOBILE_WEB", localName: "MOBILE_WEB"},
    {no: 5, name: "SALES_CHANNEL_META", localName: "META"},
    {no: 6, name: "SALES_CHANNEL_CACHE", localName: "CACHE"},
  ],
);

/**
 * @generated from message hotel.v1.RoomSelectionRequest
 */
export const RoomSelectionRequest = proto3.makeMessageType(
  "hotel.v1.RoomSelectionRequest",
  () => [
    { no: 1, name: "guest_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "hotel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "rate_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "bed_group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "occupancy_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "check_in", kind: "message", T: Date },
    { no: 8, name: "check_out", kind: "message", T: Date },
    { no: 9, name: "expected_total_price", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);


// Copyright 2021 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// @generated by protoc-gen-es v0.1.1 with parameter "target=ts+js+dts"
// @generated from file google/type/money.proto (package google.type, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import { proto3 } from '@bufbuild/protobuf'

/**
 * Represents an amount of money with its currency type.
 *
 * @generated from message google.type.Money
 */
export const Money = proto3.makeMessageType('google.type.Money', () => [
  { no: 1, name: 'currency_code', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  { no: 2, name: 'units', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
  { no: 3, name: 'nanos', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
])

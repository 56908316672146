// Copyright 2021 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// @generated by protoc-gen-es v0.1.1 with parameter "target=ts+js+dts"
// @generated from file google/type/date.proto (package google.type, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import { proto3 } from '@bufbuild/protobuf'

/**
 * Represents a whole or partial calendar date, such as a birthday. The time of
 * day and time zone are either specified elsewhere or are insignificant. The
 * date is relative to the Gregorian Calendar. This can represent one of the
 * following:
 *
 * * A full date, with non-zero year, month, and day values
 * * A month and day value, with a zero year, such as an anniversary
 * * A year on its own, with zero month and day values
 * * A year and month value, with a zero day, such as a credit card expiration
 * date
 *
 * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
 * `google.protobuf.Timestamp`.
 *
 * @generated from message google.type.Date
 */
export const Date = proto3.makeMessageType('google.type.Date', () => [
  { no: 1, name: 'year', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
  { no: 2, name: 'month', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
  { no: 3, name: 'day', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
])

// Copyright 2021 Google LLC
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file google/type/datetime.proto (package google.type, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from '@bufbuild/protobuf'
import { Duration, Message, proto3 } from '@bufbuild/protobuf'

/**
 * Represents civil time (or occasionally physical time).
 *
 * This type can represent a civil time in one of a few possible ways:
 *
 *  * When utc_offset is set and time_zone is unset: a civil time on a calendar
 *    day with a particular offset from UTC.
 *  * When time_zone is set and utc_offset is unset: a civil time on a calendar
 *    day in a particular time zone.
 *  * When neither time_zone nor utc_offset is set: a civil time on a calendar
 *    day in local time.
 *
 * The date is relative to the Proleptic Gregorian Calendar.
 *
 * If year is 0, the DateTime is considered not to have a specific year. month
 * and day must have valid, non-zero values.
 *
 * This type may also be used to represent a physical time if all the date and
 * time fields are set and either case of the `time_offset` oneof is set.
 * Consider using `Timestamp` message for physical time instead. If your use
 * case also would like to store the user's timezone, that can be done in
 * another field.
 *
 * This type is more flexible than some applications may want. Make sure to
 * document and validate your application's limitations.
 *
 * @generated from message google.type.DateTime
 */
export class DateTime extends Message<DateTime> {
  /**
   * Optional. Year of date. Must be from 1 to 9999, or 0 if specifying a
   * datetime without a year.
   *
   * @generated from field: int32 year = 1;
   */
  year = 0

  /**
   * Required. Month of year. Must be from 1 to 12.
   *
   * @generated from field: int32 month = 2;
   */
  month = 0

  /**
   * Required. Day of month. Must be from 1 to 31 and valid for the year and
   * month.
   *
   * @generated from field: int32 day = 3;
   */
  day = 0

  /**
   * Required. Hours of day in 24 hour format. Should be from 0 to 23. An API
   * may choose to allow the value "24:00:00" for scenarios like business
   * closing time.
   *
   * @generated from field: int32 hours = 4;
   */
  hours = 0

  /**
   * Required. Minutes of hour of day. Must be from 0 to 59.
   *
   * @generated from field: int32 minutes = 5;
   */
  minutes = 0

  /**
   * Required. Seconds of minutes of the time. Must normally be from 0 to 59. An
   * API may allow the value 60 if it allows leap-seconds.
   *
   * @generated from field: int32 seconds = 6;
   */
  seconds = 0

  /**
   * Required. Fractions of seconds in nanoseconds. Must be from 0 to
   * 999,999,999.
   *
   * @generated from field: int32 nanos = 7;
   */
  nanos = 0

  /**
   * Optional. Specifies either the UTC offset or the time zone of the DateTime.
   * Choose carefully between them, considering that time zone data may change
   * in the future (for example, a country modifies their DST start/end dates,
   * and future DateTimes in the affected range had already been stored).
   * If omitted, the DateTime is considered to be in local time.
   *
   * @generated from oneof google.type.DateTime.time_offset
   */
  timeOffset:
    | {
        /**
         * UTC offset. Must be whole seconds, between -18 hours and +18 hours.
         * For example, a UTC offset of -4:00 would be represented as
         * { seconds: -14400 }.
         *
         * @generated from field: google.protobuf.Duration utc_offset = 8;
         */
        value: Duration
        case: 'utcOffset'
      }
    | {
        /**
         * Time zone.
         *
         * @generated from field: google.type.TimeZone time_zone = 9;
         */
        value: TimeZone
        case: 'timeZone'
      }
    | { case: undefined; value?: undefined } = { case: undefined }

  constructor(data?: PartialMessage<DateTime>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime = proto3
  static readonly typeName = 'google.type.DateTime'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'year', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'month', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'day', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'hours', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: 'minutes', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: 'seconds', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: 'nanos', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: 'utc_offset', kind: 'message', T: Duration, oneof: 'time_offset' },
    { no: 9, name: 'time_zone', kind: 'message', T: TimeZone, oneof: 'time_offset' },
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DateTime {
    return new DateTime().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DateTime {
    return new DateTime().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DateTime {
    return new DateTime().fromJsonString(jsonString, options)
  }

  static equals(
    a: DateTime | PlainMessage<DateTime> | undefined,
    b: DateTime | PlainMessage<DateTime> | undefined,
  ): boolean {
    return proto3.util.equals(DateTime, a, b)
  }
}

/**
 * Represents a time zone from the
 * [IANA Time Zone Database](https://www.iana.org/time-zones).
 *
 * @generated from message google.type.TimeZone
 */
export class TimeZone extends Message<TimeZone> {
  /**
   * IANA Time Zone Database time zone, e.g. "America/New_York".
   *
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * Optional. IANA Time Zone Database version number, e.g. "2019a".
   *
   * @generated from field: string version = 2;
   */
  version = ''

  constructor(data?: PartialMessage<TimeZone>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime = proto3
  static readonly typeName = 'google.type.TimeZone'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'version', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TimeZone {
    return new TimeZone().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TimeZone {
    return new TimeZone().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TimeZone {
    return new TimeZone().fromJsonString(jsonString, options)
  }

  static equals(
    a: TimeZone | PlainMessage<TimeZone> | undefined,
    b: TimeZone | PlainMessage<TimeZone> | undefined,
  ): boolean {
    return proto3.util.equals(TimeZone, a, b)
  }
}

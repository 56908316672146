// @generated by protoc-gen-es v1.0.0 with parameter "target=ts+js+dts"
// @generated from file common/v1/passenger.proto (package common.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message common.v1.Passenger
 */
export const Passenger = proto3.makeMessageType(
  "common.v1.Passenger",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(Passenger_Type) },
    { no: 3, name: "age", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "family_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "given_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "loyalty_programme_accounts", kind: "message", T: LoyaltyProgrammeAccount, repeated: true },
  ],
);

/**
 * @generated from enum common.v1.Passenger.Type
 */
export const Passenger_Type = proto3.makeEnum(
  "common.v1.Passenger.Type",
  [
    {no: 0, name: "TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TYPE_ADULT", localName: "ADULT"},
    {no: 2, name: "TYPE_CHILD", localName: "CHILD"},
    {no: 3, name: "TYPE_INFANT", localName: "INFANT"},
  ],
);

/**
 * @generated from message common.v1.LoyaltyProgrammeAccount
 */
export const LoyaltyProgrammeAccount = proto3.makeMessageType(
  "common.v1.LoyaltyProgrammeAccount",
  () => [
    { no: 1, name: "airline_iata", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);


// @generated by protoc-gen-es v1.0.0 with parameter "target=ts+js+dts"
// @generated from file travelsolution/v1/travelsolution.proto (package travelsolution.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { HotelSearchQuery } from "../../hotel/v1/query_pb.js";
import { FlightOfferQuery } from "../../flight/v1/query_pb.js";
import { Passenger } from "../../common/v1/passenger_pb.js";

/**
 * @generated from message travelsolution.v1.TravelSolution
 */
export const TravelSolution = proto3.makeMessageType(
  "travelsolution.v1.TravelSolution",
  () => [
    { no: 1, name: "hotel_query", kind: "message", T: HotelSearchQuery, opt: true },
    { no: 2, name: "flight_query", kind: "message", T: FlightOfferQuery, opt: true },
    { no: 3, name: "guests", kind: "message", T: Passenger, repeated: true },
    { no: 4, name: "workflow_run_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);


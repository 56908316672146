// @generated by protoc-gen-es v1.0.0 with parameter "target=ts+js+dts"
// @generated from file flight/v1/query.proto (package flight.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Passenger } from "../../common/v1/passenger_pb.js";
import { Date } from "../../google/type/date_pb.js";
import { Money } from "../../google/type/money_pb.js";

/**
 * @generated from enum flight.v1.TravelClass
 */
export const TravelClass = proto3.makeEnum(
  "flight.v1.TravelClass",
  [
    {no: 0, name: "TRAVEL_CLASS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TRAVEL_CLASS_ECONOMY", localName: "ECONOMY"},
    {no: 2, name: "TRAVEL_CLASS_PREMIUM_ECONOMY", localName: "PREMIUM_ECONOMY"},
    {no: 3, name: "TRAVEL_CLASS_BUSINESS", localName: "BUSINESS"},
    {no: 4, name: "TRAVEL_CLASS_FIRST", localName: "FIRST"},
  ],
);

/**
 * @deprecated, use FlightOfferQuery instead
 *
 * @generated from message flight.v1.Solution
 */
export const Solution = proto3.makeMessageType(
  "flight.v1.Solution",
  () => [
    { no: 1, name: "query", kind: "message", T: FlightOfferQuery },
    { no: 2, name: "passengers", kind: "message", T: Passenger, repeated: true },
  ],
);

/**
 * @generated from message flight.v1.FlightOfferQuery
 */
export const FlightOfferQuery = proto3.makeMessageType(
  "flight.v1.FlightOfferQuery",
  () => [
    { no: 1, name: "slices", kind: "message", T: Slice, repeated: true },
    { no: 2, name: "options", kind: "message", T: Options },
  ],
);

/**
 * @generated from message flight.v1.Options
 */
export const Options = proto3.makeMessageType(
  "flight.v1.Options",
  () => [
    { no: 1, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "adults", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "children", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "infants", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "travel_class", kind: "enum", T: proto3.getEnumType(TravelClass) },
    { no: 6, name: "sort", kind: "enum", T: proto3.getEnumType(Options_Sort) },
    { no: 8, name: "search_type", kind: "enum", T: proto3.getEnumType(Options_SearchType) },
  ],
);

/**
 * @generated from enum flight.v1.Options.Sort
 */
export const Options_Sort = proto3.makeEnum(
  "flight.v1.Options.Sort",
  [
    {no: 0, name: "SORT_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SORT_BEST", localName: "BEST"},
    {no: 2, name: "SORT_SHORTEST", localName: "SHORTEST"},
    {no: 3, name: "SORT_CHEAPEST", localName: "CHEAPEST"},
    {no: 4, name: "SORT_ECO", localName: "ECO"},
  ],
);

/**
 * @generated from enum flight.v1.Options.SearchType
 */
export const Options_SearchType = proto3.makeEnum(
  "flight.v1.Options.SearchType",
  [
    {no: 0, name: "SEARCH_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SEARCH_TYPE_ONEWAY", localName: "ONEWAY"},
    {no: 2, name: "SEARCH_TYPE_ROUNDTRIP", localName: "ROUNDTRIP"},
    {no: 3, name: "SEARCH_TYPE_MULTICITY", localName: "MULTICITY"},
  ],
);

/**
 * @generated from message flight.v1.Slice
 */
export const Slice = proto3.makeMessageType(
  "flight.v1.Slice",
  () => [
    { no: 1, name: "origin_iata", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "destination_iata", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "departure_date", kind: "message", T: Date },
    { no: 5, name: "segments", kind: "message", T: RequestSegment, repeated: true },
    { no: 6, name: "filter_airline_iata", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "filter_max_stops", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 8, name: "filter_departure_time", kind: "message", T: TimeConstraint, opt: true },
    { no: 9, name: "filter_arrival_time", kind: "message", T: TimeConstraint, opt: true },
  ],
);

/**
 * @generated from message flight.v1.TimeConstraint
 */
export const TimeConstraint = proto3.makeMessageType(
  "flight.v1.TimeConstraint",
  () => [
    { no: 1, name: "min_hour", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "max_hour", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message flight.v1.RequestSegment
 */
export const RequestSegment = proto3.makeMessageType(
  "flight.v1.RequestSegment",
  () => [
    { no: 1, name: "origin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "destination", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "flight_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "carrier_iata", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "departure_date", kind: "message", T: Date },
  ],
);

/**
 * @generated from message flight.v1.LoyaltyProgrammeAccount
 */
export const LoyaltyProgrammeAccount = proto3.makeMessageType(
  "flight.v1.LoyaltyProgrammeAccount",
  () => [
    { no: 1, name: "airline_iata", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "account_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message flight.v1.OfferRequest
 */
export const OfferRequest = proto3.makeMessageType(
  "flight.v1.OfferRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "live_mode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "created_at", kind: "message", T: Timestamp },
    { no: 4, name: "slices", kind: "message", T: Slice, repeated: true },
    { no: 5, name: "passengers", kind: "message", T: Passenger, repeated: true },
    { no: 6, name: "travel_class", kind: "enum", T: proto3.getEnumType(TravelClass) },
    { no: 7, name: "offers", kind: "message", T: Offer, repeated: true },
  ],
);

/**
 * @generated from message flight.v1.Offer
 */
export const Offer = proto3.makeMessageType(
  "flight.v1.Offer",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "live_mode", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "created_at", kind: "message", T: Timestamp },
    { no: 4, name: "updated_at", kind: "message", T: Timestamp },
    { no: 5, name: "expires_at", kind: "message", T: Timestamp },
    { no: 6, name: "total_emissions_kg", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "total", kind: "message", T: Money },
    { no: 9, name: "tax", kind: "message", T: Money },
    { no: 11, name: "base", kind: "message", T: Money },
    { no: 13, name: "owner", kind: "message", T: Airline },
    { no: 14, name: "slices", kind: "message", T: Slice, repeated: true },
    { no: 15, name: "passengers", kind: "message", T: Passenger, repeated: true },
    { no: 16, name: "partial", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "passenger_identity_documents_required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "allowed_passenger_identity_document_types", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 19, name: "payment_requirements", kind: "message", T: OfferPaymentRequirement },
    { no: 20, name: "available_services", kind: "message", T: AvailableService, repeated: true },
    { no: 21, name: "conditions", kind: "message", T: Conditions },
  ],
);

/**
 * @generated from message flight.v1.Airline
 */
export const Airline = proto3.makeMessageType(
  "flight.v1.Airline",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "iata", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "logo_symbol_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "logo_lockup_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message flight.v1.OfferPaymentRequirement
 */
export const OfferPaymentRequirement = proto3.makeMessageType(
  "flight.v1.OfferPaymentRequirement",
  () => [
    { no: 1, name: "requires_instant_payment", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "price_guarantee_expires_at", kind: "message", T: Timestamp },
    { no: 3, name: "payment_required_by", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message flight.v1.AvailableService
 */
export const AvailableService = proto3.makeMessageType(
  "flight.v1.AvailableService",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "maximum_quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "metadata", kind: "message", T: AvailableServiceMetadata },
    { no: 4, name: "passenger_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "segment_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "total", kind: "message", T: Money },
  ],
);

/**
 * @generated from message flight.v1.AvailableServiceMetadata
 */
export const AvailableServiceMetadata = proto3.makeMessageType(
  "flight.v1.AvailableServiceMetadata",
  [],
);

/**
 * @generated from message flight.v1.Conditions
 */
export const Conditions = proto3.makeMessageType(
  "flight.v1.Conditions",
  () => [
    { no: 1, name: "refund_before_departure", kind: "message", T: ChangeCondition },
    { no: 2, name: "change_before_departure", kind: "message", T: ChangeCondition },
  ],
);

/**
 * @generated from message flight.v1.ChangeCondition
 */
export const ChangeCondition = proto3.makeMessageType(
  "flight.v1.ChangeCondition",
  () => [
    { no: 1, name: "allowed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "penalty", kind: "message", T: Money },
  ],
);

